import { Injectable, inject } from '@angular/core';

import { PropertySearchInputService } from '../../../../features/property-catalog/services/property-asset-selector.service';
import { PropertyDataService } from '../../../../features/property-catalog/services/property-data.service';
import { AssetTreeNode, Data, MinimalAssetTreeNode } from '../../../../features/property-catalog/models/interfaces';
import { PropertyResourceListEntity, PropertySearchResult } from '../models/property-asset-search.interafces';
import { AssetTreeHelpers } from '../../../../features/property-catalog/helpers/asset-tree-helpers';
import { TreeNode } from '../../tree/models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PropertyAssetSelectorService {
  private propertySearchInputService = inject(PropertySearchInputService);
  private propertyDataService = inject(PropertyDataService);

  public searchProperties(searchTerm: string): Observable<PropertyResourceListEntity[]> {
    return this.propertySearchInputService
      .getPropertySearchResult(searchTerm)
      .pipe(map((searchResult: PropertySearchResult) => searchResult?._embedded?.propertyResourceList || []));
  }

  public loadTreeByObjectId(objectId: string): Observable<TreeNode<MinimalAssetTreeNode, Data>> {
    return this.propertyDataService
      .getAssetTreeByObjectId(objectId)
      .pipe(map((treeData: AssetTreeNode) => AssetTreeHelpers.convertData(treeData, true)));
  }

  public loadAssetInfoByObjectId(objectId: string): Observable<any> {
    return this.propertyDataService.loadAssetInfoByObjectId(objectId);
  }

  public getPropertyById(objectId: string): Observable<AssetTreeNode> {
    return this.propertyDataService.getPropertyById(objectId);
  }
}
