import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callback',
  standalone: true,
})
export class CallbackPipe implements PipeTransform {
  transform<T, V>(element: T, callback: (element: T) => V): V | null {
    return callback ? callback(element) : null;
  }
}
