import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  FieldDefinition,
  UISchemaConfig,
  isRelationshipFieldDefinition,
  RelationshipConfig,
  RelationshipFieldDefinition,
} from '../models/ui-schema.interfaces';
import { RelationshipService } from './relationship.service';
import { DataService } from '../../../core/services/data.service';
import { TranslationNamespace } from '../../../core/models/translation.variables';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TableDisplayConfigEntity, TableUpdateConfigEntity } from '../models/asset-overview.interfaces';
import { APP_CONFIG, AppConfig } from '../../../../app.config';

@Injectable({
  providedIn: 'root',
})
export class PropertyUiConfigService extends DataService {
  private relationshipService = inject(RelationshipService);
  private http = inject(HttpClient);
  private appConfig = inject<AppConfig>(APP_CONFIG);

  private propertyApiRoot = this.appConfig.propertyCatalogApiRoot;

  // when creating we will not have an asset id and will not retrieve any relationship options
  // contextId can be assetId oder CountryId
  getUIConfig(type: string): Observable<UISchemaConfig> {
    return this.http
      .get<UISchemaConfig>(`${this.appConfig.backend}${this.propertyApiRoot}/ui-schema`, {
        params: { type },
      })
      .pipe(
        catchError(this.handleError),
        map((uiConfig: UISchemaConfig) => this.updateTranslationKeys(uiConfig)),
      ) as Observable<UISchemaConfig>;
  }

  updateTranslationKeys(uiConfig: UISchemaConfig): UISchemaConfig {
    return {
      ...uiConfig,
      type: {
        ...uiConfig.type,
        dictionaryKey: this.addTranslationKeyPrefix(uiConfig.type.dictionaryKey),
        dictionaryKeyPlural: uiConfig?.type?.dictionaryKeyPlural
          ? this.addTranslationKeyPrefix(uiConfig.type.dictionaryKeyPlural)
          : '',
      },
      fields: uiConfig.fields.map((field: FieldDefinition) => {
        if (field.dictionaryKey) {
          field.dictionaryKey = this.addTranslationKeyPrefix(field.dictionaryKey);
        }
        return field;
      }),
      tables: uiConfig.tables?.map((tableConfig) => {
        return {
          ...tableConfig,
          title: this.addTranslationKeyPrefix(tableConfig.title),
          displayConfig: this.addProcaKeysToConfig(tableConfig.displayConfig) as TableDisplayConfigEntity[],
          updateConfig: this.addProcaKeysToConfig(tableConfig.updateConfig) as TableUpdateConfigEntity[],
        };
      }),
    };
  }

  private addProcaKeysToConfig<T>(
    config: Array<TableDisplayConfigEntity | TableUpdateConfigEntity>,
  ): Array<TableDisplayConfigEntity | TableUpdateConfigEntity> {
    return config.map((entity) => {
      entity.titleKey = this.addTranslationKeyPrefix(entity.titleKey);
      entity.helpTextKey = this.addTranslationKeyPrefix(entity.helpTextKey);
      if (entity.helpTextKeyByCountryCodeNumeric) {
        Object.keys(entity.helpTextKeyByCountryCodeNumeric).forEach((key: string) => {
          if (entity.helpTextKeyByCountryCodeNumeric) {
            entity.helpTextKeyByCountryCodeNumeric[key] = this.addTranslationKeyPrefix(
              entity.helpTextKeyByCountryCodeNumeric[key],
            );
          }
        });
      }
      return entity;
    });
  }

  addTranslationKeyPrefix(translationKey: string): string {
    return translationKey ? TranslationNamespace.procaUiConfig + translationKey : '';
  }

  getFieldOptions(
    fields: FieldDefinition[],
    useParentContext: boolean,
    contextId: string,
  ): Observable<FieldDefinition[]> {
    return this._transformFieldDefinition(fields, useParentContext, contextId);
  }

  private _transformFieldDefinition(
    fieldDefinitions: FieldDefinition[],
    useParentContext: boolean,
    contextId: string,
  ): Observable<FieldDefinition[]> {
    const observableList = fieldDefinitions.map((fieldDefinition) => {
      return contextId && isRelationshipFieldDefinition(fieldDefinition)
        ? this._retrieveRelationshipOptions(fieldDefinition, useParentContext, contextId)
        : of(fieldDefinition);
    });

    return forkJoin(observableList);
  }

  private _retrieveRelationshipOptions(
    fieldDefinition: RelationshipFieldDefinition,
    useParentContext: boolean,
    contextId: string,
  ) {
    const config = fieldDefinition.updateConfig.typeSpecificConfig as RelationshipConfig;
    const action = useParentContext ? config._links.selectCreate : config._links.selectEdit;

    return this.relationshipService.getRelationshipOptions(action, useParentContext, contextId).pipe(
      map((options) => {
        const transformedFieldDefinition = { ...fieldDefinition } as RelationshipFieldDefinition;
        transformedFieldDefinition.updateConfig.typeSpecificConfig.options = options;
        return transformedFieldDefinition;
      }),
    );
  }
}
